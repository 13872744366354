.container{
    width: 100%;
    height:40vh;
    justify-content: center;
    background: url(../../assets/thoughtful-african-american.png);
    background-repeat:no-repeat;
    background-size:cover; 
    background-position:center;
}
.overlay{
    width: 100%;
    height: 100%;
    background-color: rgba(22, 22, 22, 0.63);
    display: flex;
    flex-direction: row;
    align-items: center;  
    justify-content: center;
}
.head{
    font-family: 'Work Sans';
    font-style: normal;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
}
.texts{
    font-family: 'Work Sans';
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
}
@media(min-width: 768px){
    .container{
        height: 75vh;
        margin-top: 0;
    }
    .overlay{
        padding: 30px;
    }
    .head{
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 24px;
    }
    .texts{
        font-family: 'Work Sans';
        font-style: normal;
        font-size: 20px;
        font-weight: 500;
    }
}

@media(min-width: 1280px){
    .container{
        height: 100vh;
        margin-top: 10vh;
    }
    .overlay{
        justify-content:center;
        padding: 0;
    }
    .head{
        font-family: 'Work Sans';
        font-style: normal;
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 24px;
    }
    .texts{
        font-family: 'Work Sans';
        font-style: normal;
        font-size: 24px;
        font-weight: 500;
    }
}
@media screen and (min-width:1400px){
    .container{
        height: 100vh;
    }
    .head{
        font-size: 60px;
        margin-bottom: 30px;
    }
    .texts{
        font-size: 30px;
        margin-top: 3%;
    }
}
@media screen and (min-width:1600px){
    .container{
        margin-top: 7vh;
    }
}

@media screen and (orientation:landscape) and (max-width:900px) {
    .container{
        height: 500px;
    }
    .gen{
        height: 40%;
        width: 70%;
    }
    .heroHead{
        font-size: 16px;
        line-height: 20px;
    }
    .heroText{
        font-size: 14px;
        line-height: 20px;
    }
    .gtStarted,.lrnMore{
        width: auto;
        height: 40px;
        padding: 2px 8px ;
    }
}
@media screen and (orientation:landscape) and (min-width:901px) and (max-width:1100px) {
    .container{
        height: 700px;
    }
   
}