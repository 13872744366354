@tailwind base;
@tailwind components;
@tailwind utilities;

video[poster]{
    width: 100%;
    object-fit: fill;
    }
video{
   
    object-fit: fill;
    }
video:hover ~ .pause {
    opacity: 0.7;
    display: flex;
}
.pause:hover {
    opacity: 0.7;
    display: flex;
}