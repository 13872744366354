.footer {
    background: rgba(17, 17, 17, 0.89);
}

.newsCard{
    box-shadow: 0px 16px 40px rgba(117, 142, 254, 0.25);
}

.features{
    background: url(../assets/workplace-team-cooperation-businesswoman-laptop-office\ 1.png);
    background-color: #111111;
}

.whyContainer{
    margin-top: 5vh;
}

.elearning{
    background: rgba(17, 17, 17, 0.87);
}

.read:hover span {
    display:none
  }
.read:hover i {
    display: block;
    color: white;
}
.read:hover{
    background-color: black;
    color: white;
} 

@media (min-width: 1200px){
    /* .aboutContainer{
        height: 150vh;
    } */
    .whyContainer{
        margin-top: 10vh;
    }
    .vidDiv{
        height: 80%;
    }
    .vidImg{
        width: 98%;
    }
}
@media (min-width: 1400px){
    .aboutContainer{
        margin-bottom: 10vh;
    }
    .whyContainer{
        margin-top: 0;
    }
    .whyText{
        height: 50%;
    }
    .vidDiv{
        height: 70%;
    }
    .vidImg{
        width: 98%;
    }
}



@media screen and (orientation:landscape) and (max-width:900px){
    .about{
        width: 100%;
        justify-content: space-around;
    }
    .aboutcard{
        height: 300px;
        width: 45%;
        margin-left: 0;
    }
    .aboutContainer{
        height: auto;
    }
    .aboutourproduct{
        font-size: 25px;
    }
    .makeinformed{
        font-size: 28px;
        line-height: 35px;
    }
    .solution{
        font-size: 15px;
    }
    .whyContainer{
        height: 950px;
        display: flex;
        justify-content: space-around;
        flex-direction: column-reverse;
        margin-top: 100px;
    }
    .vidDiv{
        width:90%;
    }
    .whyDivText{
        height: 350px;
        width: 100%;
        padding: 0 5%;
        margin-bottom: 50px;
    }
    .whyText{
        width: 100%;
    }
    .featureCard{
        width: 40%;
        height: 200px;
    }
    .featCont{
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    .newsCard{
        height: 448px;
        width: 334px;
    }
    .elearning{
         height: 300px;
    }
}

@media screen and (orientation:landscape) and (min-width:901px) and (max-width:1100px) {
    .aboutContainer{
        height: auto;
        margin:auto;
        margin-top: 5vh;
    } 
    .aboutcard{
        height: 300px;
        width: 300px;
        margin-left: 0;
    } 
     .about{
        width: 100%;
        justify-content: space-around;
    }
    .whyContainer{
        height: 500px;
    }
    .newsDiv{
        height: auto;
        padding: 10% 0;
    }
    .newsCard{
        margin:20px;
    }
   
    
}